export class EventListenerManager {
  private listeners: WeakMap<Element, Map<string, EventListenerOrEventListenerObject>>;

  constructor() {
    // Store listeners in a WeakMap to allow garbage collection
    this.listeners = new WeakMap();
  }

  addEventListener(element: Element, event: string, handler: EventListenerOrEventListenerObject): void {
    if (!this.listeners.has(element)) {
      this.listeners.set(element, new Map());
    }
    
    const elementListeners = this.listeners.get(element)!;
    
    // Check if this specific event type already has a handler
    if (!elementListeners.has(event)) {
      elementListeners.set(event, handler);
      element.addEventListener(event, handler);
    }
  }

  removeEventListener(element: Element, event: string): void {
    if (!this.listeners.has(element)) return;
    
    const elementListeners = this.listeners.get(element)!;
    
    if (elementListeners.has(event)) {
      const handler = elementListeners.get(event)!;
      element.removeEventListener(event, handler);
      elementListeners.delete(event);
      
      // Clean up if no more listeners for this element
      if (elementListeners.size === 0) {
        this.listeners.delete(element);
      }
    }
  }

  // Optional: Method to check if a listener exists
  hasEventListener(element: Element, event: string): boolean {
    return this.listeners.has(element) && 
           this.listeners.get(element)!.has(event);
  }
}

export const eventListenerManager = new EventListenerManager();