/**
   * Calculate area needed including any added overage
   * @param {number} typedUnitNeeded
   * @param {number} overageSelected : 0.1, 0.2, 0.3, 0.4
   * @returns {number}
   */
export function calculateAreaNeeded (typedUnitNeeded: number, overageSelected: number): number {
  let overagesCalculation: number = overageSelected > 0 ? (typedUnitNeeded * overageSelected) : 0;
  return Math.floor(typedUnitNeeded + overagesCalculation);
}

export function calculateTotalArea (pieces: number, piecesPerUnit: number): number {
  return Math.floor(pieces / piecesPerUnit);
}

/**
 * Calculate product qty needed
 * @returns {number}
 */
export function calculateCartQuantity (packageCount: number, purchaseQuantityIncrement: number): number {
  const increment = purchaseQuantityIncrement < 1 ? 1 : purchaseQuantityIncrement;
  return packageCount * increment
}

/**
   * Calculate the amount of boxes or bundles to be shown to the customer
   * @return {number}
   */
export function calculatePackagingCount (areaNeeded: number, piecesPerUnit: number, purchaseQuantityIncrement: number = 1): number {
  return Math.ceil((areaNeeded * piecesPerUnit) / purchaseQuantityIncrement);
}

export function calculateTotalPrice (calculatedCartQty: number, pricePerUnit: number): number {
  return (calculatedCartQty * pricePerUnit) / 100;
}
