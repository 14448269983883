import { Offcanvas, initTE } from 'tw-elements';
import { estimateShippingCost } from './shipping-estimator';
import zipCodeHash from './zip-code-hash.js'
import { currencyFormatter } from '../utils/numbers';
import { getServerCartJSON, cartReponseHandler } from '../utils/cartUtils';
import { CalculatorListener, TileCalculator } from './calculator';
import { eventListenerManager } from './event';

initTE({ Offcanvas });
const operationInProgress: { [key: string]: boolean } = {};

export async function addToCart(variantId: string, quantity: number, properties?: any): Promise<any> {
  try {
    if (operationInProgress[variantId]) {
      console.log(`Operation already in progress for product: ${variantId}`);
      return;
    }
    operationInProgress[variantId] = true;

    let props = properties || {};

    if (props.hasOwnProperty('__is_sample') && props['__is_sample']) {
      let productCount = await countProductsInCartById(variantId);
      if (productCount === 4) {
        throw new Error(`the requested qty exceeds the maximum qty allowed in shopping cart.`);
      }
    }

    const response = await fetch('/cart/add.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: [
          {
            id: variantId,
            quantity: quantity,
            properties: props,
          },
        ],
      }),
    });

    cartReponseHandler(response);

    const updatedCart = await response.json();
    updateSidebarCart(updatedCart);

    return updatedCart;
  } catch (error: unknown) {
    if (error instanceof Error) {
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_ERROR,
        body: error.message
      });
    } else {
      console.error('An unexpected error occurred:', error);
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_ERROR,
        body: 'An unexpected error occurred.'
      });
    }
    return null;
  } finally {
    operationInProgress[variantId] = false; // Reset the operation status
  }
}

export async function addToCartOrUpdate(productCartId: string, quantity: number, properties: any, calculations: TileCalculator, onlyUpdate: boolean = false): Promise<any> {
  try {
    if (operationInProgress[productCartId]) {
      console.log(`Operation already in progress for product: ${productCartId}`);
      return;
    }
    const cart = await getServerCartJSON();

    // Check if the product is already in the cart with same overage
    const existingItem = cart.items.find((item: any) => {
      return parseInt(item.id, 10) === parseInt(productCartId, 10)
    });

    if (existingItem) {
      operationInProgress[productCartId] = true;
      // Product is already in the cart, update the quantity and modify properties
      const updatedQuantity = (onlyUpdate ? 0 : existingItem.quantity) + quantity;

      if (!onlyUpdate) {
        properties['__boxes'] += existingItem.properties['__boxes'];
        properties['__pieces_per_unit'] += existingItem.properties['__pieces_per_unit'];
        properties['__area'] += existingItem.properties['__area'];
        properties['__pallet_area_min'] += existingItem.properties['__pallet_area_min'];
        properties['__pallet_area_max'] += existingItem.properties['__pallet_area_max'];
        properties['__pieces'] += existingItem.properties['__pieces'];
      }

      let endpoint = '/cart/change.js';
      let data: { id: any; quantity: any; properties: any } = {
        id: existingItem.key,
        quantity: updatedQuantity,
        properties: properties,
      };

      if (!properties) {
        // Update the type definition for the 'data' variable
        data = {
          id: existingItem.key,
          quantity: updatedQuantity,
          properties: {},
        };
      }

      // Make a fetch POST call to update the cart
      const updateResponse = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      cartReponseHandler(updateResponse);

      const updatedCart = await updateResponse.json();
      updateSidebarCart(updatedCart);

      return updatedCart;
    } else {
      // Product is not in the cart, add a new item
      addToCart(productCartId, quantity, properties);
    }
  } catch (error: any) {
    // Handle unexpected errors
    window.notificationsManager.open({
      type: window.notificationsManager.types.NOTIFICATION_ERROR,
      body: error.message
    });
    return null;
  } finally {
    operationInProgress[productCartId] = false; // Reset the operation status
    const existingCacheCartItem = cacheCartItemData(productCartId);
    console.log("@Cache: ", { calculations });
    let userInputAmount = 0;
    switch (calculations.type) {
      case 'tile':
      case 'item':
      case 'bundle':
        userInputAmount = calculations.typedUnitNeeded;
        break;
      case 'box':
        userInputAmount = calculations.boxes;
        break;
      case 'pallet':
        userInputAmount = calculations.pieces;
        break;
      default:
        userInputAmount = calculations.pieces;
        break;
    }
    cacheCartItemData(productCartId, {
      amount_needed: (userInputAmount || 0) + (onlyUpdate ? 0 : (existingCacheCartItem?.amount_needed || 0)),
      overage: calculations?.overage || 0
    });
  }
}

export async function editCartItem(itemKey: string, isEditing: boolean = true): Promise<any> {
  const cartContentContainer = document.querySelector('.js-cart-content');
  const deleteItemButton = cartContentContainer?.querySelector(`button.js-delete[data-item-key="${itemKey}"]`);
  const editItemButton = cartContentContainer?.querySelector(`button.js-edit[data-item-key="${itemKey}"]`);
  const itemProductType = editItemButton?.getAttribute('data-item-product-type');
  const editSampleQuantityGroup = cartContentContainer?.querySelector(`.edit-sample-quantity-group[data-item-key="${itemKey}"]`);
  const editProductSection = cartContentContainer?.querySelector(`.cart-item-editing-section[data-item-key="${itemKey}"]`);
  const cartItemsViewSectionBody = document.getElementById('cart-items-view-section-body');
  const cartItemsViewSectionFooter = document.getElementById('cart-items-view-section-footer');
  const sqftInputElement = editProductSection?.querySelector(`.sqft-input-js`);
  const overageSelectElement = editProductSection?.querySelector(`.overage-select`);

  const fnAdd = isEditing ? 'add' : 'remove';
  const fnRemove = isEditing ? 'remove' : 'add';
  switch (itemProductType) {
    case 'Sample':
      deleteItemButton?.classList[fnAdd]('hidden');
      editItemButton?.classList[fnAdd]('hidden');
      editSampleQuantityGroup?.classList[fnRemove]('hidden');
      break;
    default:
      editProductSection?.classList[fnRemove]('hidden');
      cartItemsViewSectionBody?.classList[fnAdd]('hidden');
      cartItemsViewSectionFooter?.classList[fnAdd]('hidden');
      if (sqftInputElement) {
        (sqftInputElement as HTMLInputElement).value = cacheCartItemData(itemKey)?.amount_needed ?? '';
        if (isEditing)
          sqftInputElement.dispatchEvent(new Event('input'));
      }
      if (overageSelectElement)
        (overageSelectElement as HTMLSelectElement).value = cacheCartItemData(itemKey)?.overage ?? '0';
      break;
  }
}

export async function deleteCartItem(itemKey: string): Promise<any> {

  try {
    if (operationInProgress[itemKey]) {
      console.log(`Operation already in progress for product: ${itemKey}`);
      return;
    }
    operationInProgress[itemKey] = true;
    const cartItemCount = document.getElementsByClassName('js-cart-item').length;
    let response;
    if (cartItemCount === 1) {
      // If there's only one item in the cart, clear the entire cart
      response = await fetch('/cart/clear.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } else {
      // Otherwise, remove the specific item by setting its quantity to 0
      const updates = {
        [itemKey]: 0 // Set quantity to 0 to remove the item
      };
      response = await fetch('/cart/update.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updates })
      });
    }

    const updatedCart = await response.json();
    updateSidebarCart(updatedCart);
  } catch (error) {
    console.error('Error deleting cart item:', error);
  } finally {
    operationInProgress[itemKey] = false;
    clearCacheCartItemData(itemKey);
  }

}

export async function countProductsInCartById(productId: any) {
  const cart = await getServerCartJSON();
  let count = 0;
  cart.items.forEach((item: any) => {
    if(parseInt(item.id, 10) === parseInt(productId, 10)) {
      count += item.quantity;
    }
  });
  return count;
}

export async function updateSidebarCart(updatedCart: any, itemKey: string | null = null) {
  try {
    const cart = document.getElementById('cart') as HTMLElement;
    const cartBubbles = document.querySelectorAll('[id=js-cart-count]');
    const sidenavInstance = Offcanvas.getOrCreateInstance(cart);
    const updatedCartCount = updatedCart?.item_count;
    const response = await fetch(`/?section_id=drawer-sidebar`);
    const htmlText = await response.text();

    const content = new DOMParser();

    if (content && htmlText) {
      const parsedContent = content.parseFromString(htmlText, 'text/html');
      const newCartContent = parsedContent.querySelector('.js-cart-wrapper');
      const currentCartWrappers = document.querySelectorAll('.js-cart-wrapper');

      if (newCartContent && currentCartWrappers.length) {
        currentCartWrappers.forEach((cartWrapper) => {
          cartWrapper.innerHTML = newCartContent.innerHTML;
          // Directly remove the item if the count doesn't match
          const cartItems = cartWrapper.querySelectorAll('#cart-items-view-section-body .js-cart-item');
          const cartCount = cartItems.length;

          if ((cartCount !== updatedCartCount) && itemKey) {
            const itemToRemove = cartWrapper.querySelector(`.cart-item[data-item-key="${itemKey}"]`);
            if (itemToRemove) {
              itemToRemove.remove();
            }
          }

          if (cartCount === 0 || updatedCartCount === 0) {
            setTimeout(() => {
              sidenavInstance.hide();
            }, 1000);
          } else {
            setupActionButtonListeners();
            setupEstimateShippingButtonListeners();
            setupItemEditingListeners();
            sidenavInstance.show();
          }
          cartBubbles.forEach(bubble => {
            bubble.innerHTML = cartCount.toString();
          })
        });
      }
    }
  } catch (error) {
    console.error('Error updating sidebar cart:', error);
  }
}

let cartContentClickListener: ((event: Event) => void) | null = null;

export function setupActionButtonListeners() {
  const cartContentContainer = document.querySelector('.js-cart-content');

  if (cartContentClickListener !== null && cartContentContainer) {
    cartContentContainer.removeEventListener('click', cartContentClickListener);
    cartContentClickListener = null;
  }

  cartContentClickListener = (event: Event) => {
    const target = event.target as HTMLElement;
    const deleteButton = target.closest('.js-delete') as HTMLElement;
    const editButton = target.closest('.js-edit') as HTMLElement;
    if (deleteButton) {
      const itemKey = deleteButton.dataset.itemKey;
      if (itemKey) {
        window.notificationsManager.open({
          type: window.notificationsManager.types.NOTIFICATION_WARNING,
          body: 'Are you sure you would like to remove this item from the shopping cart?',
          callback: () => deleteCartItem(itemKey)
        });
      } else {
        console.error('Item key not found.');
      }
    }
    if (editButton) {
      const itemKey = editButton.dataset.itemKey;
      if (itemKey) {
        editCartItem(itemKey);
      } else {
        console.error('Item key not found.');
      }
    }
  };

  if (cartContentContainer) {
    cartContentContainer.addEventListener('click', cartContentClickListener);
  } else {
    console.error('Cart content container not found.');
  }
}

export function setupItemEditingListeners() {
  const cartContentContainer = document.querySelector('.js-cart-content');
  if (cartContentContainer) {
    const decreaseQtyButtons = cartContentContainer?.querySelectorAll(`button.decrease-cart-item`) || [];
    const increaseQtyButtons = cartContentContainer?.querySelectorAll(`button.increase-cart-item`) || [];
    const editQtyInputs = cartContentContainer?.querySelectorAll(`input.cart-item-quantity-input`) || [];
    const updateSampleButtons = cartContentContainer?.querySelectorAll(`button.cart-sample-edit-save`) || [];
    const itemProductEditCancelButtons = cartContentContainer?.querySelectorAll(`button.cart-product-edit-cancel`) || [];
    const itemSampleEditCancelButtons = cartContentContainer?.querySelectorAll(`button.cart-sample-edit-cancel`) || [];

    // Event listeners for item sample editing quantity
    decreaseQtyButtons.forEach((button) => {
      eventListenerManager.addEventListener(button, 'click', (e) => {
        const target = e.currentTarget as HTMLButtonElement;
        const itemKey = String(target.dataset.itemKey);
        const quantityInput = cartContentContainer.querySelector(`input.cart-item-quantity-input[data-item-key="${itemKey}"]`) as HTMLInputElement;
        var newItemQty = Number(quantityInput.value);
        newItemQty = Math.max(newItemQty - 1, 0);
        quantityInput.value = String(newItemQty);
      });
    });

    increaseQtyButtons.forEach((button) => {
      eventListenerManager.addEventListener(button, 'click', (e) => {
        const target = e.currentTarget as HTMLButtonElement;
        const itemKey = String(target.dataset.itemKey);
        const quantityInput = cartContentContainer.querySelector(`input.cart-item-quantity-input[data-item-key="${itemKey}"]`) as HTMLInputElement;
        var newItemQty = Number(quantityInput.value);
        newItemQty = Math.min(newItemQty + 1, 4);
        quantityInput.value = String(newItemQty);
      });
    });

    editQtyInputs.forEach((input) => {
      eventListenerManager.addEventListener(input, 'keypress', (e) => {
        if ((e as KeyboardEvent).key !== 'Enter')
          return;
        const target = e.currentTarget as HTMLInputElement;
        const itemKey = String(target.dataset.itemKey);
        var newItemQty = Number(target.value);
        newItemQty = newItemQty;
        updateSampleQuantity(itemKey, newItemQty);
      });
    });

    updateSampleButtons.forEach((button) => {
      eventListenerManager.addEventListener(button, 'click', (e) => {
        const target = e.currentTarget as HTMLButtonElement;
        const itemKey = String(target.dataset.itemKey);
        const quantityInput = cartContentContainer.querySelector(`input.cart-item-quantity-input[data-item-key="${itemKey}"]`) as HTMLInputElement;
        const newItemQty = Number(quantityInput.value) || 0;
        updateSampleQuantity(itemKey, newItemQty);
      });
    });

    // Event listeners for item product editing
    [...itemProductEditCancelButtons, ...itemSampleEditCancelButtons].forEach((button) => {
      const itemKey = String((button as HTMLButtonElement).dataset.itemKey);
      new CalculatorListener('sqft-input-js', 'overage-select', 'cart', itemKey);
      eventListenerManager.addEventListener(button, 'click', () => {
        editCartItem(itemKey, false);
      });
    });
  }
}

export async function updateSampleQuantity(itemKey: string, itemQty: number) {
  if (itemQty > 4) {
    return window.notificationsManager.open({
      type: window.notificationsManager.types.NOTIFICATION_ERROR,
      body: 'The requested quantity exceeds the maximum allowed in the shopping cart.'
    });
  }
  const updates = {
    [itemKey]: itemQty
  };
  const response = await fetch('/cart/update.js', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ updates })
  });
  const updatedCart = await response.json();
  updateSidebarCart(updatedCart);
}

interface CartProperties {
  [key: string]: any;
  __url?: string;
}

// Event listener for elements with class 'add-to-cart'
export function setupAddToCartButtonListeners() {
  const addToCartButtons = document.querySelectorAll<HTMLElement>('.js-add-to-cart');

  addToCartButtons.forEach(addToCartButton => {
    eventListenerManager.addEventListener(addToCartButton, 'click', function (this: HTMLElement) {
      if (this.hasAttribute('data-te-toggle') && this.getAttribute('data-te-toggle') === 'modal') {
        return;
      }

      try {
        const variantId = this.dataset.variantId || null;
        const quantity = parseInt(this.dataset.quantity || '1', 10) || 1;
        let properties: CartProperties = {};
        if (this.dataset.properties) {
          try {
            properties = JSON.parse(this.dataset.properties);
          } catch (error) {
            console.error('Error parsing data-properties:', error);
          }
        }

        // Append the current URL to properties
        properties['__url'] = window.location.href;

        if (variantId) {
          addToCart(variantId, quantity, properties);
        }
      } catch (error) {
        console.error('Error handling add to cart:', error);
      }
    });
  });
}

export function setupEstimateShippingButtonListeners() {
  const estimateShippingLabels = document.querySelectorAll<HTMLElement>('.js-estimate-shipping label');

  estimateShippingLabels.forEach(estimateShippingLabel => {
    estimateShippingLabel.addEventListener('click', handleLabelClick);
  });
}

function handleLabelClick(this: HTMLLabelElement) {
  const inputZipCode = document.getElementById('js-shipping-input') as HTMLInputElement | null;

  if (!inputZipCode) {
    console.error('Input element not found.');
    return;
  }

  inputZipCode.classList.toggle('hidden');
  inputZipCode.classList.toggle('visible');

  if (!inputZipCode.classList.contains('hidden')) {
    inputZipCode.focus();
  }

  inputZipCode.addEventListener('input', handleInput);

  async function handleInput(this: HTMLElement) {
    const shippingValueElement = document.getElementById('js-shipping-value');
    if (shippingValueElement) {
      let zip = inputZipCode?.value || '';
      if (isValidZipCode(zip)) {
        // Extract the first three characters of the ZIP code
        const provinceKey = zip.substring(0, 3);

        // Check if the key exists in zipCodeHash
        if (provinceKey in zipCodeHash) {
          // @ts-ignore
          let address = { zip: zip, province: zipCodeHash[provinceKey] };
          try {
            const data = await estimateShippingCost(address);
            if (data.shipping_rates.length) {
              interface rate {
                name: string;
                price: number;
                source: string;
              }
              const rate = data.shipping_rates.find((rate: rate) => rate.name.includes('Palletized'))?.price || data.shipping_rates[0].price
              shippingValueElement.textContent = currencyFormatter.format(rate);
            }
          } catch (error) {
            console.error('Error fetching shipping rates:', error);
          }
        }
      }
    }
  }
}

function isValidZipCode(zip: string): boolean {
  if (zip.length < 5 || zip === '00000' || zip === '00000-0000') {
    return false;
  }
  const allowedCharsPattern = /^[\dA-Za-z -]+$/;
  const usZipPattern = /^\d{5}(-\d{4})?$/;
  const canPostalCodePattern = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

  return allowedCharsPattern.test(zip) && (usZipPattern.test(zip) || canPostalCodePattern.test(zip));
}

function cacheCartItemData(cartItemId: string, value?: any) {
  let data;
  try {
    data = JSON.parse(localStorage.getItem('cart-item-calculation-cache') || '') || {};
  } catch (err) {
    data = {};
  }
  if (value) {
    data[cartItemId] = value;
    localStorage.setItem('cart-item-calculation-cache', JSON.stringify(data));
  }
  return data[cartItemId];
}

function clearCacheCartItemData(cartItemId: string) {
  let data: any = {};
  try {
    data = JSON.parse(localStorage.getItem('cart-item-calculation-cache') || '') || {};
    delete data[cartItemId];
  } catch (err) {
    data = {};
  }
  localStorage.setItem('cart-item-calculation-cache', JSON.stringify(data));
}

export function refreshPDPCalculator() {
  const openCartButton = document.getElementById('OpenCartButton') as HTMLButtonElement;
  eventListenerManager.addEventListener(openCartButton, 'click', () => {
    alert("Open Cart");
  });
}