import { register, SwiperContainer } from 'swiper/element/bundle'
import { SwiperOptions } from 'swiper/types'

document.addEventListener('DOMContentLoaded', function () {
  register()
  const mainBannerSwiperEl = document.querySelector('swiper-container') as SwiperContainer

  const params: SwiperOptions = {
    injectStyles: [`
       :host {
        --swiper-navigation-size: 1.75rem;
        --swiper-navigation-color: #FFF;
      }
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        padding: 2.5rem 1rem;
        width: auto;
        right: 0;
        left: unset;
        bottom: 0;
        }
        @media (min-width: 640px) {
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          padding: 2.5rem 1rem;
        }
      }
      .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 10px;
      }
      .swiper-pagination-bullet {
        border-width: 1px;
        border-style: solid;
        border-color: #FFF;
        color: #fff;
        background: transparent;
        opacity: 100%;
        width: 8px;
        height: 8px;
      }
      .swiper-pagination-bullet-active {
        background: #FFF;
      }
    `],
    pagination: {
      clickable: true,
    },
    autoplay: {
      delay: 15000,
    },
    speed: 1500,
    loop: true,
    slidesPerView: 1
  }

  Object.assign(mainBannerSwiperEl, params)

  mainBannerSwiperEl.addEventListener('swiperresize', (event) => {
    const [ swiper ] = (event as CustomEvent).detail;
    if (swiper.width < 768) {
      swiper.navigation.disable();
    } else {
      swiper.navigation.enable();
    }
  });

  mainBannerSwiperEl.initialize();
});
