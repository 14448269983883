import { Modal } from 'tw-elements'
import { currencyFormatter } from '../utils/numbers';
import { addToCartOrUpdate } from './cart';
import { calculateAreaNeeded, calculateCartQuantity, calculatePackagingCount, calculateTotalArea, calculateTotalPrice } from './calculatorUtils';
import { eventListenerManager } from './event';


/**
 * Represents the data for a calculation.
 */
type ProductType = "pallet" | "box" | "item" | "bundle" | "tile"
interface ProducData {
  id: string
  qty: number
  type: ProductType
  uom: string
  min_order: number
  price: number
  properties: string
  is_sold_out: boolean
  tier: string
  allow_backorder: boolean
  purchase_quantity_increment: number
  pieces_per_unit: number
  lead_time_in: string
  lead_time_out: string
  pallet_area_min?: string
  pallet_area_max?: string
}
interface LeadTime {
  time: number;
  time_in_weeks: number;
  status: string;
  message: string;
  color: string;
}

export interface TileCalculator {
  typedUnitNeeded: number;
  overage: number;
  area: number;
  boxes: number;
  pieces: number;
  totalArea: number;
  totalPrice: number;
  leadTime: LeadTime | null;
  type?: ProductType;
}

type CalculatorListenerEventTarget = 'cart' | 'product';

export class CalculatorListener {
  private cartContentContainer: HTMLElement | null = null;
  private inputUnitNeededElement: HTMLInputElement;
  private minValue: number = 0;
  private unitCalculationElement: HTMLElement | null = null;
  private boxesCalculationElement: HTMLElement | null = null;
  private subtotalCalculationElement: HTMLElement | null = null;
  private piecesCalculationElement: HTMLElement | null = null;
  private addToCartButton: HTMLButtonElement | null = null;
  private updateProductCartButton: HTMLButtonElement | null = null;
  private updateSampleCartButton: HTMLButtonElement | null = null;
  private overageSelectElement: HTMLSelectElement | null = null;
  private leadMessageElement: HTMLElement | null = null;
  private minOrderQtyElement: HTMLElement | null = null;
  private minusButton: HTMLButtonElement | null = null;
  private plusButton: HTMLButtonElement | null = null;

  /**
 * The data object containing information for calculations.
 */
  private calculations: TileCalculator = {
    "typedUnitNeeded": 0,
    "overage": 0,
    "area": 0,
    "boxes": 0,
    "pieces": 0,
    "totalArea": 0,
    "totalPrice": 0,
    "leadTime": null,
  };

  /**
 * The data object containing information for calculations.
 */
  private data: ProducData = {
    "id": "",
    "qty": 0,
    "type": "item",
    "uom": "",
    "min_order": 0,
    "price": 0,
    "properties": "",
    "is_sold_out": false,
    "tier": "",
    "allow_backorder": false,
    "purchase_quantity_increment": 0,
    "pieces_per_unit": 0,
    "lead_time_in": "",
    "lead_time_out": ""
  };

  /**
   * Creates an instance of CalculatorListener.
   * @param {string} inputId - The ID of the input element.
   * @throws {Error} Throws an error if element with specified ID is not found.
   * @memberof CalculatorListener
   */
  constructor(inputId: string, overageSelectId: string, eventTarget: CalculatorListenerEventTarget = 'product', eventTargetItemKey: string = '') {
    this.cartContentContainer = document.querySelector(`.cart-item-editing-section[data-item-key="${eventTargetItemKey}"]`);
    switch (eventTarget) {
      case 'product':
        this.inputUnitNeededElement = document.getElementById(inputId) as HTMLInputElement;
        this.overageSelectElement = document.getElementById(overageSelectId) as HTMLSelectElement;
        this.unitCalculationElement = document.getElementById('unit_calculation')!;
        this.boxesCalculationElement = document.getElementById('boxes_calculation')!;
        this.subtotalCalculationElement = document.getElementById('subtotal_calculation')!;
        this.piecesCalculationElement = document.getElementById('pieces_calculation')!;
        this.leadMessageElement = document.getElementById('lead_message')!;
        this.addToCartButton = document.getElementById('add-to-cart-js') as HTMLButtonElement;
        this.minOrderQtyElement = document.getElementById('min-order-qty-js') as HTMLElement;
        this.minusButton = document.getElementById('js-minus-value') as HTMLButtonElement;
        this.plusButton = document.getElementById('js-plus-value') as HTMLButtonElement;
        break;
      case 'cart':
        this.inputUnitNeededElement = this.cartContentContainer?.querySelector(`.${inputId}`) as HTMLInputElement;
        this.overageSelectElement = this.cartContentContainer?.querySelector(`.${overageSelectId}`) as HTMLSelectElement;
        this.unitCalculationElement = this.cartContentContainer?.querySelector('.unit-calculation')!;
        this.boxesCalculationElement = this.cartContentContainer?.querySelector('.boxes-calculation')!;
        this.subtotalCalculationElement = this.cartContentContainer?.querySelector('.subtotal-calculation')!;
        this.piecesCalculationElement = this.cartContentContainer?.querySelector('.pieces-calculation')!;
        this.leadMessageElement = this.cartContentContainer?.querySelector('.lead-message')!;
        this.updateProductCartButton = this.cartContentContainer?.querySelector('.cart-product-edit-save') as HTMLButtonElement;
        this.updateSampleCartButton = this.cartContentContainer?.querySelector('.cart-sample-edit-save') as HTMLButtonElement;
        this.minOrderQtyElement = this.cartContentContainer?.querySelector('.min-order-qty-js') as HTMLElement;
        this.minusButton = this.cartContentContainer?.querySelector('.js-minus-value') as HTMLButtonElement;
        this.plusButton = this.cartContentContainer?.querySelector('.js-plus-value') as HTMLButtonElement;
        break;
    }
    // setup palletized and piece increment and decrement buttons
    this.setupIncrementDecrementButtons();

    if (this.inputUnitNeededElement)
      eventListenerManager.addEventListener(this.inputUnitNeededElement, 'input', this.handleInput.bind(this));
    if (this.overageSelectElement)
      eventListenerManager.addEventListener(this.overageSelectElement, 'change', this.handleSelectChange.bind(this));

    // Access the data-hash attribute and decode it
    const base64Hash = this.inputUnitNeededElement?.dataset.hash;
    if (base64Hash) {
      const decodedValue = this.decodeAndValidateJson(base64Hash);
      if (decodedValue) {
        this.data = decodedValue;

        const inputEvent = new Event('input');
        this.inputUnitNeededElement.dispatchEvent(inputEvent);
      }
    }
    this.minValue = this.calculateMinOrderQty();

    if (this.addToCartButton) {
      // Add to cart listeners
      if (this.addToCartButton.hasAttribute('data-te-toggle') && this.addToCartButton.getAttribute('data-te-toggle') === 'modal') {
        this.palletModalHandler();
      } else {
        eventListenerManager.addEventListener(this.addToCartButton, 'click', this.addToCart.bind(this));
      }
    }

    if (this.updateProductCartButton) {
      eventListenerManager.addEventListener(this.updateProductCartButton, 'click', this.updateProductCart.bind(this));
    }
    if (this.updateSampleCartButton) {
      eventListenerManager.addEventListener(this.updateSampleCartButton, 'click', this.updateSampleCart.bind(this));
    }
  }

  /**
   * Handles the addToCart flow for pallet type products and the necessary modal interactions.
   * @memberof CalculatorListener
   * @private
   * @returns {void}
   */
  private palletModalHandler () {
    const modalId = this.addToCartButton?.getAttribute('data-te-target');
    const modalEl = document.querySelector(modalId!) as HTMLElement;
    const modalInstance = Modal.getOrCreateInstance(modalEl);
    if (modalEl) {
      const modalAcknowledgementCheckbox = modalEl.querySelector('input[type="checkbox"]') as HTMLInputElement;
      const modalAddToCartButton = modalEl.querySelector('button#accept-pallet-terms') as HTMLButtonElement;
      if (modalAcknowledgementCheckbox) {
        modalAcknowledgementCheckbox.addEventListener('change', () => {
          modalAddToCartButton.disabled = !modalAcknowledgementCheckbox.checked;
        });
      }
      modalAddToCartButton.addEventListener('click', () => {
        const boundAddToCart = this.addToCart.bind(this);
        boundAddToCart();
        modalInstance.hide();
        modalAcknowledgementCheckbox.checked = false;
        modalAcknowledgementCheckbox.dispatchEvent(new Event('change'));
      });
    }
  }

  /**
   * Decodes a base64-encoded string.
   * @param {string} encodedValue - The base64-encoded string.
   * @returns {string} The decoded string.
   * @memberof CalculatorListener
   */
  private decodeBase64 (encodedValue: string): string {
    return atob(encodedValue);
  }

  /**
 * Decodes and validates a base64 encoded JSON string.
 * @param {string} encodedValue - The base64 encoded JSON string.
 * @returns {ProducData | null} The decoded JSON object as CalculationData, or null if an error occurs.
 */
  private decodeAndValidateJson (encodedValue: string): ProducData | null {
    try {
      const decodedValue = JSON.parse(this.decodeBase64(encodedValue));
      return decodedValue as ProducData;
    } catch (error) {
      console.error('Error decoding/validating JSON:', error);
      return null;
    }
  }

  /**
   * Get order min qty
   * @returns {number} - Minimum order in pieces(pallet/bundle) or sqft(piece)
   */
  private calculateMinOrderQty (): number {
    if (this.data.type === 'pallet') {
      return this.data.min_order;
    }

    return Math.floor(this.data.min_order / this.data.pieces_per_unit);
  }

  /**
   * Get overage selected
   * @returns {number}
   */
  private getOverage (percentage = false): number {
    if (this.overageSelectElement) {
      let overageValue = parseFloat(this.overageSelectElement.value || '0') || 0;
      if (percentage) {
        overageValue = overageValue * 100
      }
      return overageValue;
    }
    return 0;
  }

  /**
   * Get lead time data
   * @returns LeadTime
   * @memberof CalculatorListener
   */
  private getLeadTime (areaNeeded: number, qtyNeeded: number): LeadTime {
    let lead_time = parseInt(this.data.lead_time_out) || parseInt(this.data.lead_time_in);
    let message: string;
    let status = '';
    let color = 'aria-success';

    if (this.data.tier === 'E') {
      lead_time = parseInt(this.data.lead_time_in);
      message = 'Estimated availability for shipping from warehouse in ';
    } else if (this.data.qty >= qtyNeeded && this.data.qty >= this.data.min_order) {
      lead_time = parseInt(this.data.lead_time_in);
      status = 'In stock';
      message = '| ships from warehouse within ';
      color = 'aria-success';
    } else if (this.data.tier !== 'M') {
      status = 'In transit';
      message = '| ships from warehouse within ';
      color = 'aria-warning';
    } else {
      status = 'Made to order';
      message = '| ships from warehouse within ';
      color = 'aria-error';
    }

    let lead_time_in_weeks = lead_time / 7;

    if (lead_time === 7) {
      message += '1 week';
    } else if (lead_time % 7 === 0) {
      let lead_time_in_weeks_range = lead_time_in_weeks + 2;
      message += lead_time_in_weeks + ' — ' + lead_time_in_weeks_range + ' weeks';
    } else {
      message += lead_time + ' business days';
    }

    let lead_time_data: LeadTime = {
      time: lead_time,
      time_in_weeks: lead_time_in_weeks,
      status: status,
      message: message,
      color: color
    };

    if (this.data.is_sold_out || (areaNeeded < qtyNeeded && !this.data.allow_backorder)) {
      lead_time_data.status = 'Out of stock';
      lead_time_data.color = 'aria-error';
    }

    return lead_time_data;
  }

  /**
   * Retrieves the calculated values
   * @param {number} typedUnitNeeded - The value entered by the user.
   * @param {number} overageSelected - The selected overage value.
   * @returns {TileCalculator} An object containing the calculated values.
   * @memberof CalculatorListener
   */
  private getCalculations (typedUnitNeeded: number, overageSelected: number): TileCalculator {
    // calculate total needed area based on typed + overage
    let areaNeeded = calculateAreaNeeded(typedUnitNeeded, overageSelected);
    // calculate total boxes for display
    let boxesNeeded = calculatePackagingCount(areaNeeded, this.data.pieces_per_unit, this.data.purchase_quantity_increment);
    // calculate total pieces needed based on purchase quantity increment
    let piecesNeeded = calculateCartQuantity(boxesNeeded, this.data.purchase_quantity_increment);
    // calculate total price
    let totalPrice;
    // calculate total area
    let totalArea;
    // calculate lead time
    let leadTime;
    if (this.data.type === 'pallet') {
      // calculate total pieces needed based on purchase quantity increment
      piecesNeeded = boxesNeeded;
      // calculate total price
      totalPrice = calculateTotalPrice(boxesNeeded, this.data.price);
      // calculate total area
      totalArea = calculateTotalArea(boxesNeeded * this.data.purchase_quantity_increment, this.data.pieces_per_unit);
      // calculate lead time
      leadTime = this.getLeadTime(areaNeeded, boxesNeeded);
    } else if (this.data.type === 'item') {
      piecesNeeded = typedUnitNeeded;
      boxesNeeded = piecesNeeded;
      totalArea = 0;
      totalPrice = calculateTotalPrice(piecesNeeded, this.data.price);
      leadTime = this.getLeadTime(areaNeeded, piecesNeeded);
    } else {
      totalArea = calculateTotalArea(piecesNeeded, this.data.pieces_per_unit);
      leadTime = this.getLeadTime(areaNeeded, piecesNeeded);
      totalPrice = calculateTotalPrice(piecesNeeded, this.data.price);
    }

    // Ensure totalArea never falls short of the typedUnitNeeded
    totalArea = Math.max(totalArea, typedUnitNeeded);

    return this.calculations = {
      typedUnitNeeded: typedUnitNeeded,
      overage: overageSelected,
      area: areaNeeded,
      boxes: boxesNeeded,
      pieces: piecesNeeded, // Boxes refers to the packaging unit sold. A box, bundle or pallet
      totalArea: totalArea,
      totalPrice: totalPrice,
      leadTime: leadTime,
      type: this.data.type
    };
  }

  /**
   * Generates the payload for addToCart.
   * @param {number} typedUnitNeeded - The value entered by the user.
   * @param {number} overageSelected - The selected overage value.
   * @returns {object} - The payload for addToCart.
   * @memberof CalculatorListener
   */
  private getAddToCartPayload (): object {
    let payload: any = {
      __status: this.calculations.leadTime?.status,
      __message: this.calculations.leadTime?.message,
      __color: this.calculations.leadTime?.color,
      __pieces: this.calculations.pieces,
      __uom: this.data.uom,
      __properties: this.data.properties
    }
    
    switch (this.data.uom) {
      case 'pallet':
        let areaProperties = {};
        if (this.data.pallet_area_max && this.data.pallet_area_min) {
          areaProperties = {
            __pallet_area_min: parseInt(this.data.pallet_area_min) * this.calculations.pieces,
            __pallet_area_max: parseInt(this.data.pallet_area_max) * this.calculations.pieces,
          }
        } else {
          areaProperties = {
            __area: this.calculations.totalArea,
          }
        }
        payload = {
          ...areaProperties,
          ...payload,
          __pieces: this.calculations.pieces * this.data.purchase_quantity_increment,
          __pieces_per_unit: this.calculations.pieces,
        }
        break
      case 'piece':
        payload = {
          ...payload,
          __boxes: this.calculations.boxes,
        };
        break
      default:
        payload = {
          ...payload,
          __overage: this.getOverage(true),
          __area: this.calculations.totalArea,
          __boxes: this.calculations.boxes,
        }
    }
    return payload
  }

  /**
   * Sends the payload to the server for adding to cart.
   * @memberof CalculatorListener
   */
  private async addToCart () {
    const id = this.data.id;
    let quantity: number;
    if (this.data.type === 'pallet') {
      quantity = this.calculations.boxes
    } else {
      quantity = this.calculations.pieces
    }

    const payload = this.getAddToCartPayload();

    await addToCartOrUpdate(id, quantity, payload, this.calculations);
    this.inputUnitNeededElement.value = '';
  }

  /**
   * Sends the payload to the server for editing product item on the cart
   * @memberof CalculatorListener
   */
  private async updateProductCart () {
    const id = this.data.id;
    let quantity: number;
    if (this.data.type === 'pallet') {
      quantity = this.calculations.boxes
    } else {
      quantity = this.calculations.pieces
    }

    const payload = this.getAddToCartPayload();

    await addToCartOrUpdate(id, quantity, payload, this.calculations, true);
  }

  /**
   * Sends the payload to the server for editing sample item on the cart
   * @memberof CalculatorListener
   */
  private async updateSampleCart() {

  }

  /**
   * Handle message
   * @returns void
   */
  private processLeadTimeElement (): void {
    const leadStatus = this.leadMessageElement?.getElementsByTagName('strong')[0]! as HTMLElement;
    const leadMessage = this.leadMessageElement?.getElementsByTagName('span')[0]! as HTMLElement;
    leadStatus.textContent = (this.calculations.leadTime!.status).toString();
    leadStatus.removeAttribute('aria-success');
    leadStatus.removeAttribute('aria-error');
    leadStatus.removeAttribute('aria-warning');
    leadStatus.setAttribute(this.calculations.leadTime!.color, 'true');
    leadMessage.textContent = (this.calculations.leadTime!.message).toString();
  }

  private updateCalculatorView (totalArea: number, calculations: TileCalculator) {
    switch (this.data.uom) {
      case 'piece':
        if (parseInt(this.inputUnitNeededElement.value) >= 1) {
          if (this.addToCartButton)
            this.addToCartButton.disabled = false
          if (this.updateProductCartButton)
            this.updateProductCartButton.disabled = false
        }
        break;
      default:
        if ((totalArea >= this.minValue) || (typeof calculations.area === 'number' && calculations.area > this.minValue)) {
          if (this.addToCartButton)
            this.addToCartButton.disabled = false
          if (this.updateProductCartButton)
            this.updateProductCartButton.disabled = false;
          this.minOrderQtyElement?.setAttribute('style', 'visibility: hidden;')
        } else {
          if (this.addToCartButton)
            this.addToCartButton.disabled = true
          if (this.updateProductCartButton)
            this.updateProductCartButton.disabled = true;
          this.minOrderQtyElement?.setAttribute('style', 'visibility: visible;')
        }
    }


  }

  /**
   * Handles the input event.
   * @param {Event} event - The input event.
   * @memberof CalculatorListener
   */
  private handleInput (event?: Event) {
    let typedUnitNeeded: number;
    let overageSelected = this.getOverage();
    if (event) {
      typedUnitNeeded = parseInt((event.target as HTMLInputElement).value, 10) || 0;
    } else {
      typedUnitNeeded = parseInt(this.inputUnitNeededElement.value, 10) || 0;
    }
    if (this.data?.type === 'pallet') {
      const palletCoverage = this.data.purchase_quantity_increment / this.data.pieces_per_unit;
      typedUnitNeeded = Math.floor(typedUnitNeeded * palletCoverage);
    }

    if (this.data) {

      if (this.data.is_sold_out && this.addToCartButton) {
        this.addToCartButton.textContent = 'Out of stock'
        return;
      }

      // calculate total sqft coverage based on the total boxes
      // calculate total price based on total pieces
      this.getCalculations(typedUnitNeeded, overageSelected);

      if (typedUnitNeeded !== 0) {
        if (this.unitCalculationElement) {
          this.unitCalculationElement.textContent = this.calculations.totalArea.toString()
        }
        if (this.calculations.boxes && this.boxesCalculationElement) {
          this.boxesCalculationElement.textContent = this.calculations.boxes.toString();
        }
        if (this.piecesCalculationElement && !isNaN(this.calculations.pieces)) {
          this.piecesCalculationElement.textContent = (this.calculations.pieces).toString();
        }
        if (this.subtotalCalculationElement && !isNaN(this.calculations.totalPrice)) {
          this.subtotalCalculationElement.textContent = currencyFormatter.format(this.calculations.totalPrice);
        }
      }

      this.processLeadTimeElement();
      this.updateCalculatorView(this.calculations.totalArea, this.calculations)
    }
  }

  /**
   * Handles the change event of the overage select element.
   * Retrieves the selected value and incorporates it into the calculations.
   * @memberof CalculatorListener
   */
  private handleSelectChange () {
    if (this.inputUnitNeededElement) {
      this.handleInput();
    }
  }

  private setupIncrementDecrementButtons () {
    const inputField = this.inputUnitNeededElement;

    if (this.minusButton) {
      eventListenerManager.addEventListener(this.minusButton, 'click', () => this.updateInputValue(inputField, -1));
    }
    if (this.plusButton) {
      eventListenerManager.addEventListener(this.plusButton, 'click', () => this.updateInputValue(inputField, 1));
    }
  }

  private updateInputValue (inputField: HTMLInputElement, delta: number) {
    let currentValue = parseInt(inputField.value) || 0;
    currentValue += delta;
    currentValue = Math.max(currentValue, 1);
    currentValue = Math.min(currentValue, 999);

    inputField.value = currentValue.toString();

    this.handleInput();
  }  
}